import React, { useState } from "react";
import { Box, Drawer, Tab, Tabs } from "@mui/material";
import "../styles/SlidingPanel.scss"; // Import the SCSS file
import Login from "./Login";
import Register from "./Register";

interface SlidingPanelProps {
  openPanel: boolean;
  handleClose: () => void;
}

const SlidingPanel: React.FC<SlidingPanelProps> = ({
  openPanel,
  handleClose,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Drawer anchor="right" open={openPanel} onClose={handleClose}>
      <Box className="login-register-drawer">
        <Box className="main-panel">
          <Box className="panel-back-button" onClick={handleClose}>
            <Box className="panel-close-button">
              <Box className="panel-close-icon"></Box>
            </Box>
          </Box>
          <Box className="panel-heading">Access LYNX API Portal</Box>
        </Box>
        <Box className="panel-body">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Sliding Panel Tabs"
            className="tab-header"
            centered
            indicatorColor="primary"
          >
            <Tab label="LOG IN" />
            <Tab label="REGISTER" />
          </Tabs>
          <Box sx={{ padding: 2 }}>
            {tabValue === 0 && <Login />}
            {tabValue === 1 && <Register />}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SlidingPanel;
