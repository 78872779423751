import "../styles/LynxApiPage.scss";
import { Box } from "@mui/material";
import ApiSection from "./ApiSection";
import { apiCardsDetail } from "../utils/ApiCardDetails";

const LynxApiPage = () => {
  return (
    <>
      <Box className="container-lynx-apis">
        <h1 className="heading">Explore LYNX APIs</h1>
        <p className="subheading">
          Unlock powerful fleet management solutions for seamless tracking and
          optimization
        </p>
      </Box>
      {apiCardsDetail.map((data, index) => (
        <ApiSection
          key={index}
          title={data.title}
          subtitle={data.subtitle}
          description={data.description}
          buttonText={data.buttonText}
          cardData={data.cardData}
          imageIndex={data.imageIndex}
        />
      ))}
    </>
  );
};

export default LynxApiPage;
