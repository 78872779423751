import React from 'react';
import { Box } from '@mui/material';
import '../styles/ProductCard.scss';

interface ProductCardProps {
  item: {
    id: string;
    title: string;
    description: string;
    handleClick: () => void;
  };
}

const ProductCard: React.FC<ProductCardProps> = ({ item }) => {
  const { title, description, handleClick } = item;

  return (
    <Box className="card" onClick={handleClick} aria-label={`${title} card`}>
      <p>{title}</p>
      <p style={{ fontSize: '0.875rem' }}>{description}</p>
      <Box className="card-back-button">
        <Box className="card-back-arrow"></Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
