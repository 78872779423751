export const imageData = [
  {
    text: "Unlock LYNX's cutting-edge APIs and revolutionize your business",
  },
  {
    text: "Integrate with LYNX's ecosystem and leverage industry solutions",
  },
  {
    text: "Build smarter apps with LYNX's powerful APIs",
  },
  {
    text: "LYNX APIs: Driving innovation with cutting-edge tech",
  },
];
