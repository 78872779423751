import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "../styles/Register.scss";

const Register: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box className="register-container">
      <Box component="form" className="register-form">
        <Typography variant="body1" className="form-heading">
          Please fill all fields and check your email for activation code
        </Typography>

        <Box className="form-field">
          <Typography variant="body1" component="label" htmlFor="firstName">
            First Name
          </Typography>
          <TextField
            id="firstName"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
          />
        </Box>
        <Box className="form-field">
          <Typography variant="body1" component="label" htmlFor="lastName">
            Last Name
          </Typography>
          <TextField
            id="lastName"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
          />
        </Box>
        <Box className="form-field">
          <Typography variant="body1" component="label" htmlFor="email">
            Email
          </Typography>
          <TextField
            id="email"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
          />
        </Box>
        <Box className="form-field">
          <Typography variant="body1" component="label" htmlFor="password">
            Password
          </Typography>
          <TextField
            id="password"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className="form-field">
          <Typography
            variant="body1"
            component="label"
            htmlFor="confirmPassword"
          >
            Confirm Password
          </Typography>
          <TextField
            id="confirmPassword"
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className="form-field">
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body2">
                I understand and agree to the{" "}
                <a href="#" className="terms-link">
                  terms &amp; conditions
                </a>
              </Typography>
            }
          />
        </Box>
        <Box className="form-field">
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body2">
                I consent to subscribe to newsletters, surveys and information
                about LYNX API products and services.
                <br />
                Please note that you can unsubscribe or modify your preferences
                at any time under your profile.
              </Typography>
            }
          />
        </Box>
        <Button variant="contained"  sx={{ bgcolor: "#0071b9", color: "white" }} fullWidth>
          Register
        </Button>
        <Typography variant="body2" className="privacy-statement">
          Please note, that when you submit this form, we will be collecting
          your personal data. For more information about personal data
          processing, please see our{" "}
          <a href="#" className="privacy-link">
            privacy statement
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Register;
