import React, { useMemo } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/ProductPage.scss";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { productMapping } from "../utils/productMapping";
import ProductCard from "./ProductCard";

interface GridItem {
  id: string;
  title: string;
  description: string;
  handleClick: () => void;
}

const ProductPage: React.FC = React.memo(() => {
  const navigate = useNavigate();

  const handleCardClick = (route: string) => {
    navigate(`/products/${route}`);
  };

  const getGridItems = useMemo<GridItem[]>(() => {
    const userData = localStorage.getItem("userData");

    if (userData) {
      const productIds = JSON.parse(userData)?.productIDs ?? [];
      return productIds.map((id: string) => {
        const { title, description, route } = productMapping[id] ?? {};
        return {
          id,
          title,
          description,
          handleClick: () => handleCardClick(route),
        };
      });
    }

    return [];
  }, []);

  return (
    <>
      <Header title="LYNX API Portal" />
      <div className="prodcut-page">
        <Box className="Typography_H3">Develop with LYNX APIs</Box>
        <p style={{ fontSize: "0.875rem" }}>
          LYNX API Services allow you to innovate, connect and create smart
          building solutions. Each LYNX API product has been designed and
          packaged for specific use and consist of more than one API. Check out
          our offering below to discover what you can do with LYNX APIs and how
          they work.
        </p>
        <Box style={{ fontSize: "1.35rem", marginTop: "3rem" }}>TTI BUSINESS APIs</Box>
        <Grid container spacing={4} className="card-grid">
          {getGridItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ProductCard item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
      <Footer />
    </>
  );
});

export default ProductPage;
