export const apiCardsDetail = [
  {
    title: "TTI Business APIs",
    subtitle: "Enhance your fleet management experience",
    description:
      "With our APIs, you can create new solutions to streamline your truck and trailer operations. Integrate with our systems to enable features like remote monitoring, predictive maintenance, and route optimization for your fleet.",
    buttonText: "Explore TTI APIs",
    cardData: [
      {
        title: "TTI APIs",
        text: "Track the real-time location, status, and diagnostic information of your vehicles, enabling efficient fleet management and predictive maintenance.",
      },
      {
        title: "2 Way Command API",
        text: "Plan, optimize, and dynamically adjust routes based on real-time conditions, ensuring efficient delivery and transportation while minimizing costs and delays.",
      },
    ],
    imageIndex: 0,
  },
  {
    title: "Container Business APIs",
    subtitle: "Ensure cost-efficiency and operational excellence",
    description:
      "With our Maritime Container APIs, you can integrate with our systems to gain insights and control over your sea logistics operations. Track container movements, optimize routes, and manage shipments more efficiently.",
    buttonText: "Explore Container APIs",
    cardData: [
      {
        title: "COA APIs",
        text: "Get detailed equipment operating status, service status, and maintenance information, enabling proactive management and seamless integration with your container management system.",
      },
      {
        title: "2 Way Command APIs",
        text: "Manage and track your shipments throughout the entire lifecycle, including container allocation, documentation, and real-time visibility, ensuring efficient and transparent logistics operations.",
      },
    ],
    imageIndex: 1,
  },
  {
    title: "Push Notification APIs",
    subtitle: "Stay informed with real-time updates",
    description:
      "With our Push Notification APIs, you can receive timely updates and alerts regarding your operations. Get notified about important events, status changes, or critical issues, ensuring you stay informed and can take prompt action.",
    buttonText: "Subscribe Now",
    cardData: [
      {
        title: "Event Notification APIs",
        text: "Receive real-time notifications for various events related to your operations, such as status changes, delays, exceptions, and critical alerts, enabling proactive response and decision-making.",
      },
    ],
    imageIndex: 2,
  },
];
