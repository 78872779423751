import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import "../styles/ImageCard.scss";

interface ImageCardProps {
  index: number;
  text: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ index, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      className={`image-card-${index} ${isHovered ? "image-card-hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        variant="body1"
        className={`text ${isHovered ? "text-hovered" : ""}`}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ImageCard;
