import React, { useState, useEffect } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "../styles/CustomSwagger.scss";
import Header from "./Header";
import Footer from "./Footer";

interface CustomSwaggerProps {
  url: string;
  apiName: string;
}

export const CustomSwagger: React.FC<CustomSwaggerProps> = ({
  url,
  apiName,
}) => {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [activeTab, setActiveTab] = useState("apis");

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const { api_key_value } = JSON.parse(userData);
      setApiKey(api_key_value);
      setIsReady(true);
    }
  }, []);

  const requestInterceptor = (request: any) => {
    if (apiKey) {
      request.headers["x-lynx-api-key"] = apiKey;
    }
    return request;
  };

  const authorizationPlugin = {
    statePlugins: {
      auth: {
        wrapActions: {
          authorize: (oriAction: any) => (payload: any) => {
            return oriAction({
              ...payload,
              "x-lynx-api-key": {
                name: "x-lynx-api-key",
                schema: {
                  type: "apiKey",
                  in: "header",
                  name: "x-lynx-api-key",
                },
                value: apiKey,
              },
            });
          },
        },
      },
    },
  };

  const apiPlugins = [
    authorizationPlugin,
    () => ({
      components: {
        Models: () => null,
        Servers: () => null,
        ServersContainer: () => null,
      },
      wrapComponents: {
        info: () => () => null,
        authorizeBtn: () => () => null,
      },
    }),
  ];

  const schemaPlugins = [
    authorizationPlugin,
    () => ({
      components: {
        Operations: () => null,
        OperationTag: () => null,
        Servers: () => null,
        ServersContainer: () => null,
        SchemesContainer: () => null,
      },
      wrapComponents: {
        info: () => () => null,
        authorizeBtn: () => () => null,
        Models: (Original: any) => (props: any) => (
          <div className="fullscreen-schemas">
            <div className="schemas-content">
              <Original {...props} />
            </div>
          </div>
        ),
        ModelWrapper: (Original: any) => (props: any) => (
          <div className="schema-model-wrapper">
            <Original {...props} />
          </div>
        ),
      },
    }),
  ];

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return (
    <div className="custom-swagger-container">
      <Header title={apiName} />
      <div className="main-navigation">
        <div className="nav-container">
          <nav className="nav-tabs">
            <button
              className={`nav-tab ${activeTab === "apis" ? "active" : ""}`}
              onClick={() => setActiveTab("apis")}
            >
              APIs
            </button>
            <button
              className={`nav-tab ${activeTab === "schemas" ? "active" : ""}`}
              onClick={() => setActiveTab("schemas")}
            >
              Schemas
            </button>
          </nav>
        </div>
      </div>

      <div className={`content-section ${activeTab}`}>
        {activeTab === "apis" && (
          <div className="api-panel">
            <SwaggerUI
              url={url}
              docExpansion="list"
              defaultModelsExpandDepth={-1}
              defaultModelExpandDepth={-1}
              layout="BaseLayout"
              requestInterceptor={requestInterceptor}
              plugins={apiPlugins}
            />
          </div>
        )}

        {activeTab === "schemas" && (
          <div className="schema-panel">
            <SwaggerUI
              url={url}
              docExpansion="list"
              defaultModelsExpandDepth={1}
              defaultModelExpandDepth={2}
              layout="BaseLayout"
              requestInterceptor={requestInterceptor}
              plugins={schemaPlugins}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CustomSwagger;
