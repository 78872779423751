import { Box, Typography, Avatar, Menu, MenuItem } from "@mui/material";
import LynxLogo from "../assets/lynx_logo.png";
import "../styles/Header.scss";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
}
const Header = ({ title }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isProductPage =
    location.pathname === "/products" ||
    location.pathname.startsWith("/products/");

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("isLoggedIn");

    navigate("/");
    handleClose();
  };

  const getUserInitials = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const { email } = JSON.parse(userData);
      if (email) {
        const [firstName, lastName] = email.split("@")[0].split(".");
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
      }
    }
    return "";
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Box className="header-container">
      <Box className="header-left">
        {isProductPage && (
          <Box className="header-back-arrow" onClick={() => navigate(-1)}></Box>
        )}
        <Typography className="header-title">{title}</Typography>
      </Box>
      <img src={LynxLogo} alt="LYNX Logo" height={60} />

      <Box className="header-avtar-menu" onClick={handleClick} ref={menuRef}>
        {isProductPage && (
          <>
            <Avatar>{getUserInitials()}</Avatar>
            <KeyboardArrowDownIcon className={open ? "rotate" : ""} />
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleClose}>My Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
