import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    // fontFamily: "open-sans"
  },
});

export default theme;
