import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import "../styles/NotFoundPage.scss";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box className="not-found-container">
      <Typography variant="h1" className="not-found-heading" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="h5" className="not-found-subheading" gutterBottom>
        The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        className="not-found-button"
      >
        Go Back to Products
      </Button>
    </Box>
  );
};

export default NotFoundPage;
