import "../styles/InspirationPage.scss";
import { Box, Divider, Grid } from "@mui/material";
import ImageCard from "./ImageCard";
import { imageData } from "../utils/InspirationCardDetails";

const InspirationPage = () => {
  return (
    <>
      <Box className="container-inspiration">
        <h1 className="heading">Inspiration</h1>
        <p className="subheading">
          Find out how we are tracking the fleets across the globe
        </p>
      </Box>
      <h2 className="valueHeading">Creating value with new innovations</h2>
      <Divider className="divider" />
      <Grid container spacing={2} className="image-grid">
        <Grid item xs={12} sm={12} md={6}>
          <ImageCard index={0} text={imageData[0].text} />
          <ImageCard index={1} text={imageData[1].text} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ImageCard index={2} text={imageData[2].text} />
          <ImageCard index={3} text={imageData[3].text} />
        </Grid>
      </Grid>
    </>
  );
};

export default InspirationPage;
