import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import "../styles/ApiSection.scss";
import { useNavigate } from "react-router-dom";

interface ApiSectionProps {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  cardData: { title: string; text: string }[];
  imageIndex: number;
}

const ApiSection: React.FC<ApiSectionProps> = ({
  title,
  subtitle,
  description,
  buttonText,
  cardData,
  imageIndex,
}) => {
  const imageIndexResolved = imageIndex + 1;
  const imageReverseIndex = (imageIndex % 2) + 1
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const navigate = useNavigate();

  const handleOnClick = () => {
    const isUserLoggedIn = localStorage.getItem("isLoggedIn") ? true : false;
    if (isUserLoggedIn) {
      navigate("/products");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      className={`api-section-${imageIndexResolved}`}
      sx={{ "--primary-color": primaryColor }}
    >
      <Box className={`api-title-${imageReverseIndex}`}>
        <Box className="style-cicle">
          <Box className={`share-icon-${imageIndexResolved}`} />
        </Box>
        <Box className="api-title-area">
          <p className="Typography_H2">{title}</p>
          <p className="Typography_H3">{subtitle}</p>
        </Box>
      </Box>
      <Box className={`api-card-${imageReverseIndex}`}>
        <p className="api-textarea">{description}</p>
        <Box className="api-button">
          <Button
            className="nav-button nav-button-outlined"
            onClick={handleOnClick}
          >
            <span>{buttonText}</span>
          </Button>
        </Box>
      </Box>
      <Box className="api-types">
        {cardData.map((card, index) => (
          <Box key={index} className="api-types-card">
            <p className="Typography_H3">{card.title}</p>
            <p className="api-types-card-text">{card.text}</p>
            <Box onClick={handleOnClick}>
              <div className="back-arrow"></div>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ApiSection;
